import * as React from "react";

import { GlobalStyle, HomeInnerLayoutStyle } from "./style";

export default (ComposedComponent: any) => (props: any) => (
  <>
    <HomeInnerLayoutStyle {...props} loading={props?.loading?.toString()}>
      <ComposedComponent {...props} />
    </HomeInnerLayoutStyle>
    <GlobalStyle />
  </>
);
