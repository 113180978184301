import styled from "@styles/themedComponents";

export const HomeListWrapperStyle = styled.div<{ isFirstEventWidget: boolean }>`
  > *:first-child {
    margin-top: ${({ isFirstEventWidget }) => (isFirstEventWidget ? "0" : "20px")};
    margin-bottom: 20px;
  }

  > *:last-child {
    position: relative;
    padding-top: 70px;

    &:before {
      content: "";
      position: absolute;
      top: 30px;
      display: block;
      width: 100%;
      height: 10px;
      background-color: #faf9f7;
    }
  }
`;

export const FirstWidgetStyle = styled.div`
  overflow: hidden;
`;
