import SpinnerIcon from "@components/common/spinner/SpinnerIcon";
import { FullScreenSpinnerStyle } from "@views/home/homeList/FullScreenSpinner/style";

const FullScreenSpinner = () => {
  const headerHeight = document.querySelector("header")?.offsetHeight || 0;
  const hellobarHeight = document.querySelector(".hello-bar")?.getBoundingClientRect().height || 0;
  const bottomTabHeight = document.querySelector(".bottom-tab")?.clientHeight || 0;
  const tabHeaderHeight = document.querySelector(".tab-header")?.clientHeight || 0;

  return (
    <FullScreenSpinnerStyle allFixedHeight={headerHeight + hellobarHeight + bottomTabHeight + tabHeaderHeight}>
      <SpinnerIcon />
    </FullScreenSpinnerStyle>
  );
};

export default FullScreenSpinner;
