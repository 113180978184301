import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import marketingService, { eventType } from "@amondz/marketing-service";
import { HomeTab } from "@amondz/types";
import { HOME_TAB_TYPE } from "@amondz/constants";
import { Tabs } from "@amondz/design-system";
import { useHomeTabList } from "@amondz/react-query";
import { GTM_EVENT, useGtmEventLogging } from "@amondz/gtm";
import ATypePage from "@components/hoc/ATypePage";
import HomeInnerLayout from "@views/home/homeList/HomeInnerLayout";
import gtm from "@lib/utility/gtm";
import { RootStateType } from "@store/modules";
import { AuthStateType } from "@store/modules/auth";
import HomeList from "@views/home/homeList/HomeList";
import SubHomeList from "@views/home/homeList/SubHomeList";
import FullScreenSpinner from "@views/home/homeList/FullScreenSpinner";

export interface HomeListWrapperProps {
  currentActiveTabIdx: number;
}

const HomeListWrapper = (props: HomeListWrapperProps): JSX.Element => {
  const { currentActiveTabIdx } = props;
  const { isLoggedIn, userAuthState } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);
  const { data: homeTabList } = useHomeTabList();
  const router = useRouter();
  const { trigger } = useGtmEventLogging();

  useEffect(() => {
    gtm.viewMainHome(isLoggedIn, userAuthState.data?.userId);

    marketingService(eventType.viewHome, { userId: userAuthState.data?.userId, isLoggedIn });
  }, [isLoggedIn, userAuthState.data?.userId]);

  if (!homeTabList || !homeTabList.length) return <></>;

  const homeTabMap = (tab: HomeTab): JSX.Element => {
    switch (tab.linkType) {
      case HOME_TAB_TYPE.MAIN:
        return <HomeList fallback={<FullScreenSpinner />} />;
      case HOME_TAB_TYPE.SUB_PAGE:
        return <SubHomeList id={tab.linkId} fallback={<FullScreenSpinner />} />;
      default:
        return <HomeList fallback={<FullScreenSpinner />} />;
    }
  };

  const handleChangeTab = (tabIdx: number): void => {
    const targetTab = homeTabList[tabIdx];

    if (!targetTab) return;

    trigger(GTM_EVENT.SELECT_LOG, {
      itemName: targetTab.title,
      itemIndex: tabIdx + 1,
      screenName: "home",
      contentType: "GNB",
    });

    if (tabIdx === 0) {
      router.push("/");

      return;
    }

    router.push(targetTab?.id ? `/main/${targetTab.id}` : "/");
  };

  return (
    <Tabs
      type="flex"
      actionType="normal"
      size="medium"
      list={homeTabList.map((homeTab) => ({
        title: homeTab.title,
        component: homeTabMap(homeTab),
      }))}
      currentActive={currentActiveTabIdx}
      onChangeTab={handleChangeTab}
    />
  );
};

export default ATypePage(HomeInnerLayout(HomeListWrapper));
