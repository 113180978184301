import * as React from "react";
import { END } from "redux-saga";
import { GetServerSideProps } from "next";

import { homeApiV2 } from "@amondz/apis-v2";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import { mainHomeQueryKeys, subHomeQueryKeys } from "@amondz/react-query";
import { HOME_TAB_TYPE, STATUS_CODE_COMMON } from "@amondz/constants";
import superFetch, { homeApi } from "@amondz/apis-v1";
import wrapper from "@store/index";
import BasePage from "@components/base/pages/BasePage";
import HomeListWrapper from "@views/home/homeList/HomeListWrapper";
import { getAmondzLoginEx, getAmondzUuid, initializeCookie } from "@lib/utility/cookies";
import { userAuthAsync } from "@store/modules/auth";
import { STATUS_CODE_API } from "@constants/status/code/statusCodeApi";
import { setUuidOption } from "@lib/utility/common";
import { AMONDZ_UUID_KEY } from "@constants/service/auth/auth";

interface MainProps {
  currentActiveTabIdx: number;
}

const Main = (props: MainProps): JSX.Element => {
  const { currentActiveTabIdx } = props;

  return (
    <BasePage canonical={`${process.env.NEXT_PUBLIC_CANONICAL_HOST_URL}`}>
      <HomeListWrapper currentActiveTabIdx={currentActiveTabIdx} />
    </BasePage>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(async (context: any) => {
  const queryClient = new QueryClient();
  // cookie 초기화 하기
  const cookie = initializeCookie(context.req);
  // cookie 에서 amondz token 꺼내오기
  const token = getAmondzLoginEx(cookie);
  if (token) {
    // 쿠키(토큰)이 있으면 유저 인증 시도
    context.store.dispatch(userAuthAsync.request());
    context.store.dispatch(END);
    await context.store.sagaTask.toPromise();
  }

  let uuid = getAmondzUuid(cookie);
  if (!uuid) {
    const uuidOption = setUuidOption();
    context.res.setHeader(
      "Set-Cookie",
      `${AMONDZ_UUID_KEY}=${uuidOption.uuid}; expires=${uuidOption.date.toUTCString()}; path=/;`,
    );
    uuid = uuidOption.uuid;
  }
  superFetch.defaults.headers.uuid = uuid;

  try {
    const homeTabList = await queryClient.fetchQuery({
      queryKey: mainHomeQueryKeys.tabList(),
      queryFn: () => homeApiV2.getHomeTabList(),
    });
    const isEmptyTabs = !homeTabList.homeTabs || !homeTabList.homeTabs.length;

    const currentTab = homeTabList.homeTabs[0];

    if (isEmptyTabs || !currentTab) return { notFound: true };

    if (currentTab.linkType === HOME_TAB_TYPE.MAIN) {
      try {
        const home = await queryClient.fetchQuery({
          queryKey: mainHomeQueryKeys.list(),
          queryFn: homeApi.getMainHomeList,
        });

        if (home && home.status !== STATUS_CODE_COMMON.SUCCESS) return { notFound: true };
      } catch (e) {
        return { notFound: true };
      }
    }

    if (currentTab.linkType === HOME_TAB_TYPE.SUB_PAGE) {
      try {
        const subHome = await queryClient.fetchQuery({
          queryKey: subHomeQueryKeys.list(currentTab.linkId),
          queryFn: () => homeApi.getSubHomeList(currentTab.linkId),
        });

        if (
          !subHome.data ||
          subHome.status === STATUS_CODE_API.HOME_SUB_LIST_FIRST.CODE_115010000 ||
          subHome.data?.widgetList.length === 0
        )
          return { notFound: true };
      } catch (e) {
        return { notFound: true };
      }
    }

    return {
      props: {
        currentActiveTabIdx: 0,
        dehydratedState: dehydrate(queryClient),
      },
    };
  } catch (e) {
    return {
      notFound: true,
    };
  }
});
export default Main;
