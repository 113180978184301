import styled from "@styles/themedComponents";

export const MenuListStyle = styled.nav`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    column-gap: 11px;
    align-items: start;
    row-gap: 15px;
    padding: 0 15px;
  }
`;

export const MenuItemStyle = styled.li`
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img {
      width: calc(100% - 2px);
      height: 100%;
      border-radius: 8px;
    }

    span {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      line-height: 19px;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }

  @media (max-width: 374px) {
    a {
      span {
        font-size: 12px;
      }
    }
  }
`;
